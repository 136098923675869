import React from "react";
import "./myAccount.css";
import image_placeholder_1 from "../../../Assets/images/dashboard/Image_placeholder_1.png";
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import copyright from "../../../Assets/images/dashboard/copyright.png";
import { useDispatch } from "react-redux";
import {
  getUserProfileAction,
  getUserProfileNewAction,
  updateUserProfileNewAction,
  loadingAction,
  uploadProfileAction,
} from "../../../Redux/Actions";
import swal from "sweetalert";
import { getItemLocalStorage } from "../../../Utils/Util";
import { profileUploadURL } from "../../../Services/Config";
import moment from "moment";
import axios from "axios";
import { Link } from "react-router-dom";

const MyAccount = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState("");
  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);
  const [userProfileData, setUserProfileData] = useState({});
  const [userProfileNew, setUserProfileNew] = useState({});

  const [userInfo, setUserInfo] = useState({});

  const [allergisesListArr, setAllergisesListArr] = useState([
    { label: "Pollen", name: "pollen", isAdded: false },
    { label: "Trees", name: "trees", isAdded: false },
    { label: "Ragweed", name: "ragweed", isAdded: false },
    { label: "Oak", name: "oak", isAdded: false },
    { label: "Cats", name: "cats", isAdded: false },
    { label: "Dogs", name: "dogs", isAdded: false },
    { label: "Dander", name: "dander", isAdded: false },
    { label: "Pet Urine", name: "pet_Urine", isAdded: false },
    { label: "Cleaning Products", name: "cleaning_Products", isAdded: false },
    { label: "Mold", name: "mold", isAdded: false },
    { label: "Peanuts", name: "peanuts", isAdded: false },
    { label: "Shellfish", name: "shellfish", isAdded: false },
    { label: "Bees", name: "bees", isAdded: false },
  ]);
  const [medicationsListArr, setMedicationsListArr] = useState([
    { label: "ProAir HFA", name: "proAir_HFA", isAdded: false },
    { label: "Proventil HFA", name: "proventil_HFA", isAdded: false },
    { label: "Ventolin HFA", name: "Ventolin_HFA", isAdded: false },
    { label: "Xopenex HFA", name: "xopenex_HFA", isAdded: false },
    { label: "Atrovent HFA", name: "atrovent_HFA", isAdded: false },
    { label: "Albuterol Syrup", name: "albuterol_Syrup", isAdded: false },
    { label: "Albuterol Pills", name: "albuterol_Pills", isAdded: false },
    { label: "Advair", name: "advair", isAdded: false },
    { label: "Symbicort", name: "symbicort", isAdded: false },
    { label: "Dulera", name: "dulera", isAdded: false },
    { label: "Breo", name: "breo", isAdded: false },
    { label: "Flovent", name: "flovent", isAdded: false },
    { label: "Pulmicort", name: "pulmicort", isAdded: false },
    { label: "Asmanex", name: "asmanex", isAdded: false },
    { label: "QVAR", name: "qVAR", isAdded: false },
    { label: "Alvesco", name: "alvesco", isAdded: false },
    { label: "Singulair", name: "singulair", isAdded: false },
  ]);
  const [updateProfile, setUpdateProfile] = useState({
    userId: getItemLocalStorage("userId"),
    fullname: "",
    phone: "",
    address: "",
    state: "",
    city: "",
    occupation: "",
    zipcode: "",
  });
  // const [fullName, setFullName] = useState(userProfileNew.fullname);
  // const [phone, setPhone] = useState("");
  // const [address, setAddress] = useState("");
  // const [state, setSate] = useState("");
  // const [city, setCity] = useState("");
  // const [occupation, setOccupation] = useState("");
  // const [zipcode, setZipcode] = useState("");

  const [currentYear, setcurrentYear] = useState();

  const getYear = () => setcurrentYear(new Date().getFullYear());
  useEffect(() => {
    document.title = 'MOV || ACCOUNT'
  },[])
  useEffect(() => {
    getYear();
  }, []);

  useEffect(() => {
    getUserProfile();
  }, []);

  useEffect(() => {
    getUserProfileNew();
  }, []);

  const getUserProfile = async () => {
    try {
      const response = await dispatch(
        getUserProfileAction({ userId: getItemLocalStorage("userId") })
      );
      const userProfileSuccess = response?.payload;
      if (userProfileSuccess) {
        if (userProfileSuccess?.status == 200) {
          setUserProfileData(userProfileSuccess?.data?.data);
        } else {
          swal({
            title: "Error!",
            text: userProfileSuccess?.data?.message,
            buttons: false,
            icon: "error",
            timer: 2000,
          });
        }
      }
    } catch (err) {
      swal({
        title: "Error",
        text: err?.message,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
  };

  // create by me

  const getUserProfileNew = async () => {
    try {
      const response = await dispatch(
        getUserProfileNewAction({ userId: getItemLocalStorage("userId") })
      );
      const userProfileNewSuccess = response?.payload;
      if (userProfileNewSuccess) {
        if (userProfileNewSuccess?.status == 200) {
          // console.log("new---", userProfileNewSuccess?.data?.data);
          setUserProfileNew(userProfileNewSuccess?.data?.data);
          setUpdateProfile({
            userId: userProfileNewSuccess?.data?.data.userId,
            fullname: userProfileNewSuccess?.data?.data.fullname,
            phone: userProfileNewSuccess?.data?.data.phone,
            city: userProfileNewSuccess?.data?.data.city,
            state: userProfileNewSuccess?.data?.data.state,
            zipcode: userProfileNewSuccess?.data?.data.zipcode,

            occupation: userProfileNewSuccess?.data?.data.occupation,
            address: userProfileNewSuccess?.data?.data.address,
          });
        } else {
          swal({
            title: "Error!",
            text: userProfileNewSuccess?.data?.message,
            buttons: false,
            icon: "error",
            timer: 2000,
          });
        }
      }
    } catch (err) {
      swal({
        title: "Error",
        text: err?.message,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
  };

  const handleFormdata = (e) => {
    // console.log("target", e.target.value);
    // console.log("name", e.target.name);
    const value = e.target.value;
    const name = e.target.name;
    setUpdateProfile({
      ...updateProfile,
      [name]: value,
    });
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      // console.log("updateP", updateProfile);
      const response = await dispatch(
        updateUserProfileNewAction(updateProfile)
      );
      const updateUserProfileNewSuccess = response?.payload;
      if (updateUserProfileNewSuccess) {
        if (updateUserProfileNewSuccess?.status == 200) {
          swal({
            title: "Success!",
            text: updateUserProfileNewSuccess?.data?.message,
            buttons: false,
            icon: "success",
            timer: 2000,
          });
        } else {
          swal({
            title: "Error!",
            text: updateUserProfileNewSuccess?.data?.message,
            buttons: false,
            icon: "error",
            timer: 2000,
          });
        }
      }
    } catch (err) {
      swal({
        title: "Error",
        text: err?.message,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
  };

  const onFileChange = async (e) => {
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("file", e.target.files[0]);
    formData.append("userId", getItemLocalStorage("userId"));

    // Details of the uploaded file
    // console.log(e.target.files[0]);

    // Request made to the backend api
    // Send formData object
    try {
      const response = await dispatch(uploadProfileAction(formData));
      const uploadProfileSuccess = response?.payload;
      if (uploadProfileSuccess) {
        if (uploadProfileSuccess?.status == 200) {
          getUserProfile();
        } else {
          swal({
            title: "Error!",
            text: uploadProfileSuccess?.data?.message,
            buttons: false,
            icon: "error",
            timer: 2000,
          });
        }
      }
    } catch (err) {
      swal({
        title: "Error",
        text: err?.message,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
  };

  return (
    <>
      <div className="my-account">
        <div className="account_header">
          <div className="account_header_titel">Edit Profile</div>
          <div className="account_header_desc">
            Here you can edit your account settings.
          </div>
        </div>
        <div className="account_body">
          <div className="account_body_left">
            <div className="profile_header">
              <div className="profileImg">
                <img
                  src={
                    userProfileData.profileImage != ""
                      ? profileUploadURL + userProfileData.profileImage
                      : image_placeholder_1
                  }
                  alt="profile-img"
                  className="profile_img"
                />
                <input
                  type="file"
                  onChange={onFileChange}
                  accept="image/*"
                  id="file-input"
                  className="file-input"
                />
                <label className="file-label" for="file-input">
                  <p className="edit_pic">+</p>
                </label>
              </div>
            </div>
            <div className="profile_name">{userProfileData.username}</div>
            <div className="profile_title">
              <strong>
                {" "}
                <u>Date of Birth</u>
              </strong>
              <div className="profile_title_cont">
                {userProfileData && userProfileData?.dob != null
                  ? userProfileData && userProfileData?.dob != ""
                    ? moment(
                        new Date(
                          userProfileData &&
                            userProfileData?.dob.split("/").reverse().join("/")
                        ).toUTCString()
                      ).format("L")
                    : "--"
                  : "--"}
              </div>
            </div>
            <div className="profile_title">
              <strong>
                <u>Username</u>
              </strong>
              <div className="profile_title_cont">{userProfileData.email}</div>
            </div>
            {/* <div className="profile_title">
              <strong>
                <u>Kit ID</u>
              </strong>
              <div className="profile_title_cont">
                {userProfileData.kitNumber}
              </div>
            </div> */}
            <div className="profile_title">
              <strong>
                <u>Member Since</u>
              </strong>
              <div className="profile_title_cont">
                {userProfileNew && userProfileNew?.dob != null
                  ? userProfileData && userProfileData?.memberSince != ""
                    ? moment(
                        new Date(
                          userProfileNew &&
                            userProfileNew?.memberSince
                              .split("/")
                              .reverse()
                              .join("/")
                        ).toUTCString()
                      ).format("L")
                    : "--"
                  : "--"}
              </div>
            </div>

            <Link to={"/reset-password"} className="reset_pass">
              Reset Password
            </Link>
          </div>
          <div className="account_body_right">
            <form
              className="form-card account_body_right_form"
              onSubmit={handleEditSubmit}
            >
              <div class="row justify-content-between text-left">
                <div class="form-group col-sm-6  flex-column d-flex Myaccount_form_label">
                  <label>Full Name</label>
                  <input
                    class="form-control"
                    name="fullname"
                    style={{ borderRadius: "0px" }}
                    // value={userProfileNew.fullname}
                    defaultValue={userProfileNew.fullname}
                    onChange={handleFormdata}
                    required
                  />
                </div>
                <div class="form-group col-sm-6 flex-column d-flex Myaccount_form_label">
                  <label for="exampleInputEmail1">Home Address</label>
                  <input
                    class="form-control"
                    name="address"
                    style={{ borderRadius: "0px" }}
                    defaultValue={userProfileNew.address}
                    onChange={handleFormdata}
                  />
                </div>
                <div class="form-group col-sm-6 flex-column d-flex Myaccount_form_label">
                  <label for="exampleInputEmail1">City</label>
                  <input
                    class="form-control"
                    name="city"
                    style={{ borderRadius: "0px" }}
                    defaultValue={userProfileNew.city}
                    onChange={handleFormdata}
                  />
                </div>
                <div class="form-group col-sm-6 flex-column d-flex Myaccount_form_label">
                  <label for="exampleInputEmail1">State</label>
                  <input
                    class="form-control"
                    name="state"
                    style={{ borderRadius: "0px" }}
                    defaultValue={userProfileNew.state}
                    onChange={handleFormdata}
                  />
                </div>
                <div class="form-group col-sm-6 flex-column d-flex Myaccount_form_label">
                  <label for="exampleInputEmail1">Zip</label>
                  <input
                    class="form-control"
                    name="zipcode"
                    style={{ borderRadius: "0px" }}
                    onChange={handleFormdata}
                    defaultValue={userProfileNew.zipcode}
                    required
                  />
                </div>
                <div class="form-group col-sm-6 flex-column d-flex Myaccount_form_label">
                  <label for="exampleInputEmail1">Mobile</label>
                  <input
                    class="form-control"
                    name="phone"
                    style={{ borderRadius: "0px" }}
                    onChange={handleFormdata}
                    required
                    defaultValue={userProfileNew.phone}
                  />
                </div>
                <div class="form-group col-sm-6 flex-column d-flex Myaccount_form_label">
                  <label for="exampleInputEmail1">E-mail</label>
                  <input
                    class="form-control"
                    name="phone"
                    style={{ borderRadius: "0px" }}
                    readonly="readonly"
                    defaultValue={userProfileData.email}
                  />
                </div>
                <div class="form-group col-sm-6 flex-column d-flex Myaccount_form_label">
                  <label for="exampleInputEmail1">Occupation</label>
                  <input
                    class="form-control"
                    name="occupation"
                    style={{ borderRadius: "0px" }}
                    onChange={handleFormdata}
                    defaultValue={userProfileNew.occupation}
                  />
                </div>
              </div>
              <button type="submit" class="btn btn account_body_right_button">
                Save Changes
              </button>
            </form>
          </div>
        </div>
        {/* <div className="myAccount_futter_bottom">
          <img src={copyright} alt="" /> {currentYear} MOV Health
        </div> */}
      </div>
    </>
  );
};

export default MyAccount;
